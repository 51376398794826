import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {GameService} from '../../../services/game.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html'
})
export class SignInComponent implements OnInit {

  mode = 'sign-in'; // sign-in / register

  email: string;
  password: string;
  password2: string;
  displayName: string;

  constructor(public auth: AuthService, public router: Router, public game: GameService) { }

  ngOnInit(): void {
  }

  signIn(): void {
    this.auth.signIn(this.email, this.password);
  }

  register(): void {
    if (this.formValid() && this.passwordsMatch()) {
      this.auth.register(this.email, this.password, this.displayName);
    }
  }

  formValid(): boolean {
    return this.email?.length >= 3 &&
    this.displayName?.length >= 3 &&
    this.password?.length >= 4 &&
    this.password2?.length >= 4;
  }

  passwordsMatch(): boolean {
    return (this.password !== undefined && this.password2 !== undefined) && this.password === this.password2;
  }

}
