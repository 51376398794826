import {Injectable} from '@angular/core';
import {forkJoin, from, Observable, of} from 'rxjs';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import {GameService} from './game.service';
import {User} from '../models/user';
import firebase from 'firebase/compat/app';
import UserCredential = firebase.auth.UserCredential;
import firestore = firebase.firestore;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$: Observable<User>;
  user: User;

  loading = false;
  errorMessage;

  constructor(private afAuth: AngularFireAuth,
              private afs: AngularFirestore,
              private router: Router,
              private gameService: GameService) {
    // Subscribe to current auth user data from database (updates when auth changes)
    this.user$ = this.afAuth.authState.pipe(
      switchMap(authState => {
        if (authState) {
          return from(this.afs.doc<User>(`users/${authState.email}`).valueChanges());
        } else {
          return of<User>(null);
        }
      }));

    this.user$.subscribe(user => this.user = user);
  }

  ///// Login/Signup //////

  signIn(email: string, password: string): void {
    this.errorMessage = null;
    this.loading = true;
    if (!email) {
      this.loading = false;
      this.errorMessage = 'Please enter email address!';
    } else if (!password) {
      this.loading = false;
      this.errorMessage = 'Please enter password';
    } else {
      this.afAuth.signInWithEmailAndPassword(email, password).catch((error) => {
        this.loading = false;
        this.errorMessage = 'Please send this error to the organisation team:\n\n' + error.message;
      }).then(() => {
        this.makeUserManager(email).then(() => {
          this.router.navigate(['/']).then(() => {
            this.loading = false;
          });
        }).catch(err => { this.loading = false; this.errorMessage = 'Please send this error to the organisation team:\n\n' + err; });
      }).catch(err => { this.loading = false; this.errorMessage = 'Please send this error to the organisation team:\n\n' + err; });
    }
  }

  register(email: string, password: string, displayName: string): void {
    // TODO: Fix this, if user already created
    this.errorMessage = null;
    this.loading = true;
    if (!email) {
      this.loading = false;
      this.errorMessage = 'Please enter email address!';
    } else if (!password) {
      this.loading = false;
      this.errorMessage = 'Please enter password';
    } else {
      this.afAuth.createUserWithEmailAndPassword(email, password).catch((error) => {
        this.loading = false;
        this.errorMessage = 'Please send this error to the organisation team:\n\n' + error.message;
      }).then((credential: UserCredential) => {
        this.initUser(credential.user, displayName).then(() => {
          this.router.navigate(['/']).then(() => {
            this.loading = false;
          }).catch(err => { this.loading = false; this.errorMessage = 'Please send this error to the organisation team:\n\n' + err; });
        }).catch(err => { this.loading = false; this.errorMessage = 'Please send this error to the organisation team:\n\n' + err; });
      });
    }
  }

  logout(): void {
    this.afAuth.signOut().then(() => {
      this.router.navigate(['/sign-in']);
      // window.location.reload();
    }).catch((error) => {
      alert(error.message);
    });
  }

  currentUserIsManagerForGame(): Observable<boolean> {
    return forkJoin([
      this.gameService.currentGame$.pipe(take(1)), this.user$.pipe(take(1)) // Join both queries
    ]).pipe(
      map(res => { // res contains array with game data at 0 and user data at 1
        return !!(res[1] && res[1].roles.manager?.includes(res[0].id)); // if user is admin for current game
      }),
      tap(isAdmin => {
        if (!isAdmin) {
          // DON'T SHOW THIS ERROR FOR THIS SITE - ANYONE THAT LOGS IN CAN GET ACCESS!
          // this.errorMessage = 'You are not authorized to access this site.'; // Set error message and logout if unauthorised
          // this.logout();
          this.makeUserManager(this.user.email).then(() => {
              window.location.reload();
          });

        }
      })
    );
  }

  private initUser(user, displayName: string): Promise<any> {
    // Sets user data to firestore on login
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.email}`);
    const data = {
      email: user.email,
      uid: user.uid,
      displayName,
      roles: {
        manager: [
          this.gameService.currentGame.id // set as manager for current game
        ]
      }
    };
    return userRef.set(data);
  }

  private makeUserManager(email: string): Promise<any> {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${email}`);
    // The below will not duplicate
    return userRef.update({'roles.manager': firestore.FieldValue.arrayUnion(this.gameService.currentGame.id)});
  }

  submissionsOpen(): boolean {
    return Date.now() <= this.gameService.currentGame.submissionDeadline.toMillis() ||
      this.gameService.currentGame.submissionDeadlineOverride.some(x => x === this.user.email);
  }
}
